<template>
  <div
    v-if="selectedProjectTable"
    ref="table-container"
    class="flex-grow-1"
    style="width: 0"
  >
    <dynamic-data-table
      ref="data-table"
      table-title="Records"
      :table-records="tableRecords"
      :table-headers="tableHeaders"
      :table-columns="tableColumns"
      :table-relations="selectedProjectTable.related_tables"
      :is-loading="$wait.is('table.*')"
      :can-view-relations="true"
      :can-edit="
        (selectedProjectTable.permissions.update ||
          $can('update', 'project-table')) &&
        !inlineEditMode
      "
      :can-delete="
        selectedProjectTable.permissions.delete ||
        $can('delete', 'project-table-records')
      "
      :can-refresh="true"
      :has-options="true"
      :page="tablePage"
      :items-total="tableItemsTotal"
      :items-per-page="tableItemsPerPage"
      :items-per-page-options="[25, 50, 100, 250, -1]"
      :can-select="true"
      :table-height="`${tableHeight}px`"
      :table-id="selectedProjectTable.id"
      :project-id="selectedProjectTable.project"
      :use-default-export="false"
      class="ant-panel"
      @reloadData="fetchTableRecordsAndColumns"
      @deleteItem="setupDelete"
      @editItem="setupEdit"
      @selectedRecords="selectedRecords"
      @onOptionsUpdate="onOptionsUpdate"
    >
      <template #table-buttons>
        <v-tooltip
          v-if="
            selectedProjectTable.permissions.update ||
            $can('update', 'project-table')
          "
          bottom
        >
          <template #activator="{ on, attrs }">
            <v-icon
              class="ant-icon ml-2"
              dense
              v-bind="attrs"
              :color="inlineEditMode ? 'primary' : ''"
              @click="inlineEditMode = !inlineEditMode"
              v-on="on"
              >mdi-pencil-box-outline</v-icon
            >
          </template>
          <span>Toggle inline edit</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-icon
              class="ant-icon ml-2"
              dense
              v-bind="attrs"
              :color="tablesSidebarShown ? 'primary' : ''"
              @click="toggleTablesSidebar"
              v-on="on"
              >mdi-page-layout-sidebar-left</v-icon
            >
          </template>
          <span>Toggle tables sidebar</span>
        </v-tooltip>
        <v-tooltip
          v-if="
            selectedProjectTable.permissions.read ||
            $can('read', 'project-table-revisions')
          "
          bottom
        >
          <template #activator="{ on, attrs }">
            <v-icon
              class="ant-icon ml-2"
              dense
              v-bind="attrs"
              @click="$router.push({ name: 'table-revisions' })"
              v-on="on"
              >mdi-history</v-icon
            >
          </template>
          <span>Revisions</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-icon
              class="ant-icon ml-2"
              dense
              v-bind="attrs"
              @click="$router.push({ name: 'table-query' })"
              v-on="on"
              >mdi-database-search</v-icon
            >
          </template>
          <span>Query maker</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-icon
              class="ant-icon ml-2"
              dense
              v-bind="attrs"
              @click="tableSearchDialog = true"
              v-on="on"
              >mdi-magnify</v-icon
            >
          </template>
          <span>Search table</span>
        </v-tooltip>
      </template>
      <template
        v-for="header in tableHeaders"
        #[`header.${header?.value}`]="{ header: slotHeader }"
      >
        <div :key="`${header?.value}`" class="d-inline-flex flex-row">
          <span>{{ slotHeader.value }}</span>
          <div class="d-flex align-start">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-icon
                  color="var(--v-error-darken1)"
                  style="font-size: 8px"
                  dense
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-asterisk
                </v-icon>
              </template>
              <span>Required field</span>
            </v-tooltip>
          </div>
        </div>
      </template>
      <template
        v-for="header in tableHeaders"
        #[`item.${header?.value}`]="{ value, rowId, item }"
      >
        <table-view-editable-cell
          v-if="inlineEditMode"
          :key="`${rowId}.${header?.value}`"
          :item="item"
          :column-name="header?.value"
          :rules="{ required: requiredCheck, email: rules.email }"
          :project-id="selectedProjectTable.project"
          @set-item="inlineUpdate($event, header.value)"
        />
        <table-view-cell
          v-else
          :key="`${rowId}.${header?.value}`"
          :item="item"
          :column-name="header?.value"
          :type="header.type"
          :table-id="selectedProjectTable.id"
          :project-id="selectedProjectTable.project"
          @previewFile="$refs['data-table'].setDocumentPreview"
        />
      </template>
      <template #table-actions>
        <date-time-picker
          :min-date="selectedProjectTable.created_at"
          :max-date="currentDate"
          @selectedTimeStamp="selectedTableTimestamp"
        >
          <template #date-time-input="{ on, attrs }">
            <v-text-field
              v-model="tableTimestamp"
              label="Timestamp"
              prepend-inner-icon="mdi-clock-time-eight-outline"
              style="max-width: 200px; margin: 0 10px"
              hide-details
              dense
              single-line
              readonly
              filled
              clearable
              v-bind="attrs"
              v-on="on"
              @click:clear="fetchTableRecords"
            />
          </template>
        </date-time-picker>

        <v-combobox
          v-model="sessionFilter"
          :items="sortedWorkflowSessions"
          menu-props="auto"
          label="Session"
          hide-details
          clearable
          style="max-width: 250px; margin: 0 10px"
          item-text="name"
          item-value="id"
          prepend-inner-icon="mdi-progress-clock"
          single-line
          dense
          filled
          @change="setSessionFilter"
          @click:clear="fetchTableRecords"
        />
        <v-dialog
          key="add-update-record"
          v-model="recordDialog"
          max-width="500px"
        >
          <template #activator="{ on, attrs }">
            <v-tooltip
              v-if="
                selectedProjectTable.permissions.create ||
                $can('create', 'project-table-records')
              "
              bottom
            >
              <template #activator="{ on: onTooltip }">
                <v-icon dense class="ant-icon" v-on="{ ...on, ...onTooltip }">
                  mdi-plus
                </v-icon>
              </template>
              <span>New record</span>
            </v-tooltip>
          </template>
          <v-card>
            <v-card-title class="justify-center text-uppercase headline">
              {{ recordItem.id ? 'Update' : 'Add' }} Record
            </v-card-title>
            <v-divider />
            <div class="px-10 pb-5">
              <v-form ref="form" @submit.prevent>
                <ant-input
                  v-for="column in tableColumns"
                  :key="column.id"
                  :label="column.name"
                  :is-optional="!column.required"
                  :hint="column.hint"
                >
                  <template #input-field>
                    <v-text-field
                      v-if="column.type === 'text'"
                      v-model="recordItem[column.name]"
                      :placeholder="column.name"
                      hide-details
                      dense
                      filled
                      type="text"
                      :rules="[requiredCheck(column, recordItem[column.name])]"
                    />
                    <v-textarea
                      v-else-if="column.type === 'text-field'"
                      v-model="recordItem[column.name]"
                      :placeholder="column.name"
                      hide-details
                      dense
                      filled
                      type="text"
                      :rules="[requiredCheck(column, recordItem[column.name])]"
                    />
                    <v-text-field
                      v-else-if="column.type === 'integer'"
                      v-model="recordItem[column.name]"
                      :placeholder="column.name"
                      :rules="[requiredCheck(column, recordItem[column.name])]"
                      hide-details
                      dense
                      filled
                      type="number"
                    />
                    <v-checkbox
                      v-else-if="column.type === 'boolean'"
                      v-model="recordItem[column.name]"
                      :placeholder="column.name"
                      :rules="[requiredCheck(column, recordItem[column.name])]"
                      hide-details
                      dense
                      filled
                    />

                    <v-menu
                      v-else-if="column.type === 'date'"
                      v-model="dateMenu[column.name]"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template #activator="{ on, attrs }">
                        <v-text-field
                          v-model="recordItem[column.name]"
                          :placeholder="column.name"
                          prepend-inner-icon="mdi-calendar"
                          clearable
                          :rules="[
                            requiredCheck(
                              column.required,
                              recordItem[column.name]
                            ),
                          ]"
                          readonly
                          hide-details
                          dense
                          filled
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="recordItem[column.name]"
                        @input="dateMenu[column.name] = false"
                      />
                    </v-menu>
                    <v-text-field
                      v-else-if="column.type === 'link'"
                      v-model="recordItem[column.name]"
                      :placeholder="column.name"
                      type="text"
                      :rules="[requiredCheck(column, recordItem[column.name])]"
                      hide-details
                      dense
                      filled
                    />
                    <v-text-field
                      v-else-if="column.type === 'email'"
                      v-model="recordItem[column.name]"
                      :placeholder="column.name"
                      type="text"
                      :rules="[
                        requiredCheck(column, recordItem[column.name]),
                        rules.email,
                      ]"
                      hide-details
                      dense
                      filled
                    />
                    <v-select
                      v-else-if="column.type === 'dropdown'"
                      v-model="recordItem[column.name]"
                      :items="column.options_value"
                      :placeholder="column.name"
                      :rules="[requiredCheck(column, recordItem[column.name])]"
                      hide-details
                      dense
                      filled
                    />
                    <v-select
                      v-else-if="column.type === 'table'"
                      v-model="recordItem[column.name]"
                      :items="projectTables"
                      item-value="id"
                      item-text="name"
                      :placeholder="column.name"
                      :rules="[requiredCheck(column, recordItem[column.name])]"
                      clearable
                      hide-details
                      dense
                      filled
                    />
                    <v-file-input
                      v-else-if="column.type === 'document'"
                      v-model="recordItem[column.name]"
                      :placeholder="column.name"
                      hide-details
                      dense
                      filled
                    />
                    <v-text-field
                      v-else-if="column.type === 'float'"
                      v-model="recordItem[column.name]"
                      :placeholder="column.name"
                      type="number"
                      :rules="[requiredCheck(column, recordItem[column.name])]"
                      hide-details
                      dense
                      filled
                    />
                    <v-combobox
                      v-else-if="column.type === 'sbscode'"
                      v-model="recordItem[column.name]"
                      :items="sbsRecords"
                      item-text="code"
                      :placeholder="column.name"
                      type="text"
                      :rules="[requiredCheck(column, recordItem[column.name])]"
                      hide-details
                      dense
                      filled
                    >
                      <template #item="{ item }">
                        <div v-if="item.label" class="d-flex align-center">
                          {{ item.label }}
                          <v-divider vertical class="mx-2" />
                          <span style="font-size: 11px; color: grey">
                            {{ item.code }}
                          </span>
                        </div>
                        <div v-else>
                          {{ item.code }}
                        </div>
                      </template>
                    </v-combobox>
                  </template>
                </ant-input>
              </v-form>
            </div>

            <v-card-actions class="ant-border-top ant-dialog-actions-bg">
              <v-spacer />
              <v-btn color="primary" text small @click="closeRecordDialogs()">
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                small
                elevation="0"
                :loading="
                  $wait.is('table.record.update') ||
                  $wait.is('table.record.create')
                "
                @click="saveRecord()"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template #table-options-menu>
        <v-list-item v-if="tableSelection.length === 1" @click="copyRecordId">
          <v-list-item-icon style="margin-right: 10px">
            <v-icon dense> mdi-content-copy </v-icon>
          </v-list-item-icon>
          <v-list-item-title>Copy record ID</v-list-item-title>
        </v-list-item>

        <v-list-item @click.stop="$refs['csv-import'].$refs.input.click()">
          <v-list-item-icon style="margin-right: 10px">
            <v-icon dense> mdi-file-import </v-icon>
          </v-list-item-icon>
          <v-list-item-title> Import CSV </v-list-item-title>
          <v-file-input
            ref="csv-import"
            v-model="csvImportFile"
            accept=".csv"
            style="display: none"
            @change="importCsvData()"
          />
        </v-list-item>

        <v-list-item
          v-if="
            selectedProjectTable.permissions.configure ||
            $can('create', 'project-table-revisions')
          "
          @click.stop="tableRevisionDialog = true"
        >
          <v-list-item-icon style="margin-right: 10px">
            <v-icon dense> mdi-history </v-icon>
          </v-list-item-icon>
          <v-list-item-title>Create revision</v-list-item-title>
        </v-list-item>

        <v-list-item
          v-if="
            tableSelection.length > 0 &&
            (selectedProjectTable.permissions.delete ||
              $can('delete', 'project-table-records'))
          "
          @click.stop="recordDeleteMultipleDialog = true"
        >
          <v-list-item-icon style="margin-right: 10px">
            <v-icon>mdi-delete-sweep</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            Delete {{ tableSelection.length }} records
          </v-list-item-title>
        </v-list-item>

        <v-list-item
          v-if="
            selectedProjectTable.permissions.delete ||
            $can('delete', 'project-table-records')
          "
          @click.stop="clearTableDialog = true"
        >
          <v-list-item-icon style="margin-right: 10px">
            <v-icon>mdi-delete-empty</v-icon>
          </v-list-item-icon>
          <v-list-item-title> Clear table </v-list-item-title>
        </v-list-item>

        <v-list-item @click="exportToCSV">
          <v-list-item-icon style="margin-right: 10px">
            <v-icon dense> mdi-content-copy </v-icon>
          </v-list-item-icon>
          <v-list-item-title>Export to CSV</v-list-item-title>
        </v-list-item>
      </template>
    </dynamic-data-table>
    <delete-dialog
      :dialog="recordDeleteDialog"
      title="Are you sure you want to remove this record?"
      :loading="$wait.is('table.record.delete')"
      @closeDialog="closeRecordDialogs"
      @deleteAction="deleteRecord()"
    />
    <delete-dialog
      :dialog="recordDeleteMultipleDialog"
      :title="`Are you sure you want to remove ${tableSelection.length} ${
        tableSelection.length > 1 ? 'records' : 'record'
      }?`"
      :loading="$wait.is('table.records.delete')"
      @closeDialog="closeRecordDialogs"
      @deleteAction="deleteSelection()"
    />
    <delete-dialog
      :dialog="clearTableDialog"
      title="Are you sure you want to remove all records within this table"
      :loading="$wait.is('table.clear')"
      @closeDialog="closeRecordDialogs"
      @deleteAction="clearTable"
    />
    <revision-dialog
      :display-dialog="tableRevisionDialog"
      :table="selectedProjectTable"
      @closeDialog="tableRevisionDialog = false"
    />
    <table-search-dialog
      :dialog="tableSearchDialog"
      @closeDialog="tableSearchDialog = false"
      @searchRecords="searchTableRecords"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DynamicDataTable from '@/components/DynamicDataTable.vue';
import DeleteDialog from '@/components/DeleteDialog.vue';
import FileHandlerService from '@/services/file-handler';
import DataHelper from '@/services/data-helper';
import RevisionDialog from '@/components/Dialogs/RevisionDialog.vue';
import moment from 'moment';
import TableSearchDialog from '@/components/Project/Tables/TableSearchDialog.vue';
import DateTimePicker from '@/components/DateTimePicker.vue';
import AntInput from '@/components/AntInput.vue';
import TableViewCell from '@/components/Project/Tables/TableView/TableViewCell.vue';
import TableViewEditableCell from '@/components/Project/Tables/TableView/TableViewEditableCell.vue';

export default {
  name: 'TableView',
  components: {
    TableViewEditableCell,
    TableViewCell,
    AntInput,
    DateTimePicker,
    TableSearchDialog,
    RevisionDialog,
    DeleteDialog,
    DynamicDataTable,
  },
  data: () => {
    return {
      recordItem: {},
      recordDialog: false,
      recordDeleteDialog: false,
      recordDeleteMultipleDialog: false,
      clearTableDialog: false,
      tableRevisionDialog: false,
      rules: {
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || 'Invalid e-mail.';
        },
      },
      csvImportFile: undefined,
      tableSelection: [],
      sessionFilter: undefined,
      tableHeight: undefined,

      dateMenu: {},

      tablePage: 1,
      tableItemsPerPage: 50,
      tableSearchDialog: false,
      tableTimeStampDialog: false,
      tableTimestamp: null,
      tableSortBy: {
        column: undefined,
        order: undefined,
      },
      inlineEditMode: false,
      cachedRecords: {},
    };
  },
  computed: {
    ...mapGetters([
      'project',
      'projectTables',
      'selectedProjectTable',
      'tableRecords',
      'tableColumns',
      'projectSessions',
      'tablesSidebarShown',
      'sbsRecords',
      'tableHeadersSlots',
      'tableMetadata',
    ]),
    tableItemsTotal() {
      return this.tableMetadata?.count || 0;
    },
    tableHeaders() {
      return this.tableHeadersSlots.map((h) => ({
        ...h,
        hasHeaderSlot: h.required,
      }));
    },
    sortedWorkflowSessions() {
      return [...this.projectSessions].sort((a, b) =>
        moment(b.created_at).diff(moment(a.created_at))
      );
    },
    currentDate() {
      return moment().format('YYYY-MM-DD');
    },
  },
  watch: {
    async selectedProjectTable(value) {
      this.sessionFilter = undefined;
      this.inlineEditMode = false;
      await this.fetchTableRecordsAndColumns();
      if (value) {
        this.$refs['data-table'].tableModel = [];
        this.tableSelection = [];
      }
    },
    tableColumns(value) {
      if (this.tableColumns.find((item) => item.type === 'sbscode')) {
        this.$store.dispatch('loadSbsRecords', {
          projectId: this.project.id,
        });
      }
    },
  },
  async mounted() {
    if (!this.selectedProjectTable) {
      this.$store.commit(
        'set_project_table',
        this.projectTables.find(
          (table) => table.id === this.$route.params.tableId
        )
      );
    } else {
      await this.fetchTableRecordsAndColumns();
    }
    await this.$store.dispatch('fetchProjectSessions');
    this.tableHeight =
      (this.$refs['table-container']?.offsetHeight || 0) - 64 - 59;

    if (this.$route.query.document) {
      let blob = await FileHandlerService.fetchRecordDocument(
        this.$route.query.document,
        this.selectedProjectTable.project,
        this.selectedProjectTable.id
      );

      if (blob.type === 'application/pdf' || blob.type.startsWith('image/')) {
        this.$refs['data-table'].setDocumentPreview(URL.createObjectURL(blob));
      } else {
        FileHandlerService.downloadRecordDocument(
          this.$route.query.document,
          this.selectedProjectTable.project,
          this.selectedProjectTable.id
        );
      }
    }
  },
  methods: {
    selectedTableTimestamp(timestamp) {
      this.tableTimestamp = timestamp;

      let body = {
        project: {
          id: this.selectedProjectTable.project,
        },
        table: {
          id: this.selectedProjectTable.id,
        },
        timestamp: moment(timestamp).unix(),
      };

      this.$store.dispatch('fetchRecordsOnTimestamp', { body });
    },
    clearTable() {
      this.$store
        .dispatch('clearTable', {
          projectId: this.selectedProjectTable.project,
          tableId: this.selectedProjectTable.id,
        })
        .then(() => {
          this.closeRecordDialogs();
        });
    },
    exportToCSV() {
      const table = this.selectedProjectTable;
      const body = {
        tables: [
          {
            tableId: table.id,
            as: table.name,
            columns: this.tableColumns.map((column) => {
              return {
                name: column.name,
                as: column.name,
              };
            }),
          },
        ],
      };
      this.$store
        .dispatch('tablesQuery/runExportTableQueryRecords', {
          projectId: this.project.id,
          body,
        })
        .then(() => {
          this.closeRecordDialogs();
        });
    },
    copyRecordId() {
      if (this.tableSelection.length === 1) {
        const e = document.createElement('textarea');
        e.value = this.tableSelection[0].id;
        e.setAttribute('readonly', '');
        e.style.position = 'absolute';
        e.style.left = '-9999px';
        document.body.appendChild(e);

        const selected =
          document.getSelection().rangeCount > 0
            ? document.getSelection().getRangeAt(0)
            : false;
        e.select();
        try {
          document.execCommand('copy');
          document.body.removeChild(e);
          if (selected) {
            document.getSelection().removeAllRanges();
            document.getSelection().addRange(selected);
            this.$store.commit('showNotification', {
              content: `Copied record id to clipboard`,
              color: 'success',
            });
          }
        } catch (err) {
          this.$store.commit('showNotification', {
            content: `Failed to copy record id to clipboard`,
            color: 'error',
          });
        }
      }
    },
    setSessionFilter() {
      if (this.sessionFilter) {
        this.tableTimestamp = null;
        this.$store.dispatch('fetchRecordsInSession', {
          projectId: this.selectedProjectTable.project,
          tableId: this.selectedProjectTable.id,
          filters: {
            session: this.sessionFilter.id,
          },
        });
      }
    },
    selectedRecords(records) {
      this.tableSelection = records;
    },
    requiredCheck(column, value) {
      if (column.required === 1 && column.type !== 'boolean') {
        return () => !!value || 'This field is required';
      } else {
        return true;
      }
    },
    onOptionsUpdate(event) {
      this.tablePage = event.page;
      this.tableItemsPerPage = event.itemsPerPage;
      if (event.sortBy?.length) {
        this.tableSortBy = {
          column: event.sortBy[0],
          order: event.sortDesc[0],
        };
      } else {
        this.tableSortBy = {
          column: undefined,
          order: undefined,
        };
      }
      this.fetchTableRecords();
    },
    getFetchFilters() {
      let filters = {
        offset: (this.tablePage - 1) * this.tableItemsPerPage,
        limit: this.tableItemsPerPage,
      };

      if (this.sessionFilter) {
        filters.session = this.sessionFilter.id;
      }
      return filters;
    },
    async fetchTableRecordsAndColumns() {
      await this.$store.dispatch('fetchTable', {
        projectId: this.selectedProjectTable.project,
        tableId: this.selectedProjectTable.id,
        filters: this.getFetchFilters(),
        sortBy: this.tableSortBy.column ? this.tableSortBy : undefined,
      });
    },
    async fetchTableRecords() {
      await this.$store.dispatch('fetchFilteredRecordsWithMeta', {
        projectId: this.selectedProjectTable.project,
        tableId: this.selectedProjectTable.id,
        filters: this.getFetchFilters(),
        sortBy: this.tableSortBy.column ? this.tableSortBy : undefined,
      });
    },
    closeRecordDialogs() {
      this.recordDialog = false;
      this.recordDeleteDialog = false;
      this.recordDeleteMultipleDialog = false;
      this.clearTableDialog = false;
      this.recordItem = Object.assign({}, {});
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
    },
    setupDelete(record) {
      this.recordItem = Object.assign({}, record);
      this.recordDeleteDialog = true;
    },
    setupEdit(record) {
      this.recordItem = Object.assign({}, record);
      this.recordDialog = true;
    },
    deleteRecord() {
      let body = {
        project: {
          id: this.selectedProjectTable.project,
        },
        table: {
          id: this.selectedProjectTable.id,
        },
      };

      this.$store
        .dispatch('deleteRecord', { recordId: this.recordItem.id, body })
        .then(() => {
          this.closeRecordDialogs();
        });
    },
    async saveRecord() {
      if (this.$refs.form.validate()) {
        if (this.recordItem.id) {
          // update record
          let data = Object.assign({}, this.recordItem);
          const body = await this.parseRecordBody(data);

          this.$store
            .dispatch('updateRecord', { recordId: this.recordItem.id, body })
            .then(() => {
              this.closeRecordDialogs();
            });
        } else {
          // create record
          const body = await this.parseRecordBody(this.recordItem);

          this.$store.dispatch('createRecord', { body }).then(() => {
            this.closeRecordDialogs();
          });
        }
      }
    },
    deleteSelection() {
      let body = {
        project: {
          id: this.selectedProjectTable.project,
        },
        table: {
          id: this.selectedProjectTable.id,
        },
        records: this.tableSelection.map((record) => record.id),
      };

      this.$store.dispatch('deleteRecords', body).then(() => {
        this.tableSelection = [];
        this.$refs['data-table'].tableModel = undefined;
        this.closeRecordDialogs();
        this.fetchTableRecords();
      });
    },
    async parseRecordBody(data) {
      let body = {
        project: {
          id: this.selectedProjectTable.project,
        },
        table: {
          id: this.selectedProjectTable.id,
        },
        record: {},
      };

      if (data.session) {
        body.session = {
          id: data.session,
        };
      }

      for (const column of this.tableColumns) {
        if (column.type === 'document') {
          if (data[column.name]) {
            if (!data[column.name].id) {
              await FileHandlerService.handleFile(data[column.name]).then(
                (value) => {
                  body.record[column.name] = value;
                }
              );
            }
          } else {
            body.record[column.name] = null;
          }
        } else {
          body.record[column.name] = DataHelper.parseData(
            column.type,
            data[column.name],
            column.required
          );
        }
      }

      return body;
    },
    async importCsvData() {
      if (this.csvImportFile.name.split('.').pop() !== 'csv') {
        this.$store.commit('showNotification', {
          content: 'Only CSV files are accepted',
          color: 'error',
        });
        this.csvImportFile = undefined;
      } else {
        let body = {
          project: {
            id: this.selectedProjectTable.project,
          },
          table: {
            id: this.selectedProjectTable.id,
          },
        };

        await FileHandlerService.handleFile(this.csvImportFile).then(
          (value) => {
            body.records = value.data;
          }
        );

        this.$store.dispatch('importRecords', { data: body }).then(() => {
          this.csvImportFile = undefined;
          this.fetchTableRecords();
        });
      }
    },
    searchTableRecords(body) {
      this.$store.dispatch('searchInTable', { body });
    },
    toggleTablesSidebar() {
      this.$store.commit('toggle_tables_sidebar');
    },
    async inlineUpdate(item, column) {
      if (item.id) {
        const editedRecord = {
          ...(this.cachedRecords[item.id] || item),
          [column]: item[column],
        };
        this.cachedRecords[item.id] = editedRecord;
        const body = await this.parseRecordBody(editedRecord);

        this.$store
          .dispatch('updateRecord', { recordId: item.id, body })
          .then((record) => {
            if (record) {
              this.$delete(this.cachedRecords, item.id);
            }
          });
      }
    },
  },
};
</script>

<style scoped></style>
